import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import serviceWorker from "../serviceWorker";
import API from "../service";
import toast from "react-hot-toast";
import { Navigate, useNavigate } from "react-router-dom";
import { error } from "jquery";

const initialState = {
  userData: {
    loading: false,
    data: {},
    error: "",
  },

  loginData: {
    loading: false,
    data: {},
    error: "",
  },
  authenticateStatus: {
    loading: false,
    data: {},
    error: "",
  },
  qrCodedata: {
    loading: false,
    data: {},
    error: "",
  },
  forgotPasswordData: {
    loading: false,
    data: {},
    error: "",
  },
  resetPasswordData: {
    loading: false,
    data: {},
    error: "",
    is_success: false,
  },
  changePasswordData: {
    loading: false,
    data: {},
    error: "",
  },
  commissionData: {
    loading: false,
    data: {},
    error: "",
  },
  commissionAdd: {
    is_addedd: false,
    loading: false,
    data: {},
    error: "",
  },
};
// get reviewer list api
export const getUserData = createAsyncThunk("getUserData", async () => {
  const response = await API.get("/admin/profile");
  if (response.unauthenticated) {
    toast.error(response.message);
    localStorage.removeItem("token");
    reviewerList;
    window.location.href = "/login";
  } else {
    if (response.status) {
      return response.data;
    } else {
      throw new Error(response.data);
    }
  }
});

export const loginApi = createAsyncThunk(
  "login",
  async ({ email, password, otp }) => {
    const response = await API.post("admin/login", {
      email: email,
      password: password,
      otp: otp,
    });
    if (response.status === true) {
      toast.success("Login Successfully");
    } else {
      toast.error(response.message);
    }
    return response;
  }
);

export const checkAuthenticateStatus = createAsyncThunk(
  "checkAuthenticateStatus",
  async ({ email, password }) => {
    const response = await API.post("admin/check_Authenticate_status", {
      email: email,
      password: password,
    });
    if (response.status === true) {
      // toast.success("Login Successfully");
    } else {
      toast.error(response.message);
    }
    return response;
  }
);
export const creatQRCodeApi = createAsyncThunk(
  "creatQRCodeApi",
  async ({ email, password }) => {
    const response = await API.post("admin/create_qr_for_admin", {
      email: email,
      password: password,
    });
    if (response.status === true) {
      // toast.success("Login Successfully");
    } else {
      toast.error(response.message);
    }
    return response.data;
  }
);
export const forgot_password_Api = createAsyncThunk(
  "forgot_password",
  async ({ email }) => {
    const response = await API.post("admin/forgotpassword", {
      email: email,
    });
    if (response.status === true) {
      toast.success(response.message);
    } else {
      toast.error(response.message);
    }
    return response;
  }
);
export const reset_password_Api = createAsyncThunk(
  "reset_password",
  async ({ newpassword, confirmpassword, token }) => {
    const response = await API.post(
      `admin/reset_password?resetToken=${token}`,
      {
        newpassword: newpassword,
        confirmpassword: confirmpassword,
      }
    );
    if (response.status === true) {
      toast.success(response.message);
    } else {
      toast.error(response.message);
    }
    return response.data;
  }
);
export const ChangePassword_Api = createAsyncThunk(
  "change_password",
  async ({ password, new_password }) => {
    const response = await API.put("admin/changepassword", {
      password: password,
      new_password: new_password,
    });
    if (response.status === true) {
      toast.success(response.message);
    } else {
      toast.error(response.message);
    }
    return response.data;
  }
);
export const getCommissionData = createAsyncThunk(
  "getCommissionData",
  async () => {
    const response = await API.get("/platform_charges/display_charges");
    if (response.unauthenticated) {
      toast.error(response.message);
      localStorage.removeItem("token");
      reviewerList;
      window.location.href = "/login";
    } else {
      if (response.status) {
        return response.data;
      } else {
        throw new Error(response.data);
      }
    }
  }
);
export const addCommition = createAsyncThunk(
  "addReviewer",
  async ({ data }) => {
    const response = await API.post(
      `/platform_charges/insert_and_update_charges`,
      data
    );
    if (response.status) {
      toast.success(response.message || "Product updated successfully");
      return response.data;
    } else {
      toast.error(response.message || "Something went wrong");
      throw new Error(response.data);
    }
  }
);

const apiSlice = createSlice({
  name: "api",
  initialState,
  reducers: {
    handleSearchQuery: (state, action) => {
      state.body = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUserData.pending, (state) => {
        return {
          ...state,
          userData: {
            loading: true,
            data: {},
            error: "",
          },
        };
      })
      .addCase(getUserData.fulfilled, (state, action) => {
        return {
          ...state,
          userData: {
            loading: false,
            data: action.payload.profile,
            error: "",
          },
        };
      })
      .addCase(getUserData.rejected, (state, action) => {
        return {
          ...state,
          userData: {
            loading: false,
            data: {},
            error: action.payload,
          },
        };
      })
      .addCase(loginApi.pending, (state) => {
        return {
          ...state,
          loginData: {
            loading: true,
            data: {},
            error: "",
          },
        };
      })
      .addCase(loginApi.fulfilled, (state, action) => {
        return {
          ...state,
          loginData: {
            loading: false,
            data: action.payload,
            error: "",
          },
        };
      })
      .addCase(loginApi.rejected, (state, action) => {
        return {
          ...state,
          loginData: {
            loading: false,
            data: {},
            error: action.payload,
          },
        };
      })
      .addCase(checkAuthenticateStatus.pending, (state) => {
        return {
          ...state,
          authenticateStatus: {
            loading: true,
            data: {},
            error: "",
          },
        };
      })
      .addCase(checkAuthenticateStatus.fulfilled, (state, action) => {
        return {
          ...state,
          authenticateStatus: {
            loading: false,
            data: action.payload,
            error: "",
          },
        };
      })
      .addCase(checkAuthenticateStatus.rejected, (state, action) => {
        return {
          ...state,
          authenticateStatus: {
            loading: false,
            data: {},
            error: action.payload,
          },
        };
      })
      .addCase(creatQRCodeApi.pending, (state) => {
        return {
          ...state,
          qrCodedata: {
            loading: true,
            data: {},
            error: "",
          },
        };
      })
      .addCase(creatQRCodeApi.fulfilled, (state, action) => {
        return {
          ...state,
          qrCodedata: {
            loading: false,
            data: action.payload,
            error: "",
          },
        };
      })
      .addCase(creatQRCodeApi.rejected, (state, action) => {
        return {
          ...state,
          qrCodedata: {
            loading: false,
            data: {},
            error: action.payload,
          },
        };
      })
      .addCase(forgot_password_Api.pending, (state) => {
        return {
          ...state,
          forgotPasswordData: {
            loading: true,
            data: {},
            error: "",
          },
        };
      })
      .addCase(forgot_password_Api.fulfilled, (state, action) => {
        return {
          ...state,
          forgotPasswordData: {
            loading: false,
            data: action.payload,
            error: "",
          },
        };
      })
      .addCase(forgot_password_Api.rejected, (state, action) => {
        return {
          ...state,
          forgotPasswordData: {
            loading: false,
            data: {},
            error: action.payload,
          },
        };
      })
      .addCase(reset_password_Api.pending, (state) => {
        return {
          ...state,
          resetPasswordData: {
            loading: true,
            data: {},
            error: "",
            is_success: false,
          },
        };
      })
      .addCase(reset_password_Api.fulfilled, (state, action) => {
        return {
          ...state,
          resetPasswordData: {
            loading: false,
            data: action.payload,
            error: "",
            is_success: true,
          },
        };
      })
      .addCase(reset_password_Api.rejected, (state, action) => {
        return {
          ...state,
          resetPasswordData: {
            loading: false,
            data: "",
            error: action.payload,
            is_success: false,
          },
        };
      })
      .addCase(ChangePassword_Api.pending, (state) => {
        return {
          ...state,
          changePasswordData: {
            loading: true,
            data: {},
            error: "",
          },
        };
      })
      .addCase(ChangePassword_Api.fulfilled, (state, action) => {
        return {
          ...state,
          changePasswordData: {
            loading: false,
            data: action.payload,
            error: "",
          },
        };
      })
      .addCase(ChangePassword_Api.rejected, (state, action) => {
        return {
          ...state,
          changePasswordData: {
            loading: false,
            data: {},
            error: action.payload,
          },
        };
      })
      .addCase(getCommissionData.pending, (state) => {
        return {
          ...state,
          commissionData: {
            loading: true,
            data: {},
            error: "",
          },
        };
      })
      .addCase(getCommissionData.fulfilled, (state, action) => {
        console.log("action.payload.display_charges", action.payload);
        return {
          ...state,
          commissionData: {
            loading: false,
            data: action.payload.display_charges,
            error: "",
          },
        };
      })
      .addCase(getCommissionData.rejected, (state, action) => {
        return {
          ...state,
          commissionData: {
            loading: false,
            data: {},
            error: action.payload,
          },
        };
      })

      .addCase(addCommition.pending, (state) => {
        state.commissionAdd = {
          ...state.commissionAdd,
          is_addedd: false,
          loading: true,
          error: null,
        };
      })
      .addCase(addCommition.fulfilled, (state, action) => {
        const platformPercentage = action.payload.platform_percentage;

        // Convert the current state to a plain object for inspection
        const plainState = JSON.parse(JSON.stringify(state));
        console.log("Plain state before update:", plainState);

        // Access the current commission data from state
        const currentCommissionData = plainState.commissionData.data || [];

        // Create a copy of the current data to modify
        const updatedCommissionData = [...currentCommissionData];

        if (platformPercentage) {
          const { platform_type, percentage, price_range } = platformPercentage;

          // Find the index of the item with the matching platform_type
          const index = updatedCommissionData.findIndex(
            (item) => item.platform_type === platform_type
          );

          // Update the existing entry if platform_type exists in current data
          if (index !== -1) {
            if (platform_type === "B2B" && price_range) {
              updatedCommissionData[index] = {
                ...updatedCommissionData[index],
                type: "price_range",
                price_range: price_range,
              };
            } else if (percentage !== undefined) {
              updatedCommissionData[index] = {
                ...updatedCommissionData[index],
                type: "percentage",
                percentage: percentage,
              };
            }
          } else {
            // Add new data if the platform_type does not exist
            if (platform_type === "B2B" && price_range) {
              updatedCommissionData.push({
                platform_type: platform_type,
                type: "price_range",
                price_range: price_range,
              });
            } else if (percentage !== undefined) {
              updatedCommissionData.push({
                platform_type: platform_type,
                type: "percentage",
                percentage: percentage,
              });
            }
          }
        }

        return {
          ...state,
          commissionData: {
            ...state.commissionData,
            data: updatedCommissionData,
          },
          commissionAdd: {
            is_addedd: true,
            loading: false,
            data: platformPercentage,
          },
        };
      })

      .addCase(addCommition.rejected, (state, action) => {
        state.commissionAdd = {
          is_addedd: false,
          loading: false,
          data: state.commissionAdd.data,
          error: action.error.message || "Something went wrong",
        };
      });
  },
});

export const { handleSearchQuery } = apiSlice.actions;

export default apiSlice.reducer;
