import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import API from "../service";
import toast from "react-hot-toast";

const initialState = {
  DashboardData: {
    loading: false,
    data: [],
    error: "",
  },

  regionList: {
    loading: false,
    data: [],
    error: "",
  },
  languageList: {
    loading: false,
    data: [],
    error: "",
  },
  platformList: {
    loading: false,
    data: [],
    error: "",
  },
  genersList: {
    loading: false,
    data: [],
    error: "",
  },
};

// fetching region list
export const getdashboardData = createAsyncThunk(
  "getdashboardData",
  async () => {
    const response = await API.get("dashboard");
    console.log("");
    if (response.status) {
      return response.data;
    } else {
      toast.error(response.message || "something went wrong");
      throw new Error(response.message || "something went wrong");
    }
  }
);

// fetching region list
export const getRegionList = createAsyncThunk("getRegionList", async () => {
  const response = await API.get("region_and_platform/regionlist");
  if (response.status) {
    return response.data;
  } else {
    toast.error(response.message || "something went wrong");
    throw new Error(response.message || "something went wrong");
  }
});

// fetching platformList list
export const getPlatformList = createAsyncThunk("getPlatformList", async () => {
  const response = await API.get("region_and_platform/platformlist");
  if (response.status) {
    return response.data;
  } else {
    toast.error(response.message || "something went wrong");
    throw new Error(response.message || "something went wrong");
  }
});

// fetching language list
export const getLanguageList = createAsyncThunk("getLanguageList", async () => {
  const response = await API.get("region_and_platform/languageslist");
  if (response.status) {
    return response.data;
  } else {
    toast.error(response.message || "something went wrong");
    throw new Error(response.message || "something went wrong");
  }
});

// fetching geners list
export const getGenersList = createAsyncThunk("getGenersList", async () => {
  const response = await API.get("region_and_platform/genreslist");
  if (response.status) {
    return response.data;
  } else {
    toast.error(response.message || "something went wrong");
    throw new Error(response.message || "something went wrong");
  }
});

const globalListReducer = createSlice({
  name: "globalListReducer",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getdashboardData.pending, (state) => {
        return {
          ...state,
          DashboardData: {
            loading: true,
            data: [],
          },
        };
      })
      .addCase(getdashboardData.fulfilled, (state, action) => {
        console.log(" action.payload.dashboard", action.payload.dashboard);
        return {
          ...state,
          DashboardData: {
            loading: false,
            data: action.payload.dashboard,
          },
        };
      })
      .addCase(getdashboardData.rejected, (state, action) => {
        return {
          ...state,
          DashboardData: {
            loading: false,
            error: action.error.message,
            data: [],
          },
        };
      })
      .addCase(getRegionList.pending, (state) => {
        return {
          ...state,
          regionList: {
            loading: true,
            data: [],
          },
        };
      })
      .addCase(getRegionList.fulfilled, (state, action) => {
        const regionData = action.payload.region_list.map((region) => ({
          value: region._id,
          label: region.region_name,
        }));
        return {
          ...state,
          regionList: {
            loading: false,
            data: regionData,
          },
        };
      })
      .addCase(getRegionList.rejected, (state, action) => {
        return {
          ...state,
          regionList: {
            loading: false,
            error: action.error.message,
            data: [],
          },
        };
      })
      .addCase(getPlatformList.pending, (state) => {
        return {
          ...state,
          platformList: {
            loading: true,
            data: [],
          },
        };
      })
      .addCase(getPlatformList.fulfilled, (state, action) => {
        const plateformData = action.payload.platform_list.map((platform) => ({
          value: platform._id,
          label: platform.platform_name,
        }));
        return {
          ...state,
          platformList: {
            loading: false,
            data: plateformData,
          },
        };
      })
      .addCase(getPlatformList.rejected, (state, action) => {
        return {
          ...state,
          platformList: {
            loading: false,
            error: action.error.message,
            data: [],
          },
        };
      })
      .addCase(getLanguageList.pending, (state) => {
        return {
          ...state,
          languageList: {
            loading: true,
            data: [],
            error: "",
          },
        };
      })
      .addCase(getLanguageList.fulfilled, (state, action) => {
        const languageData = action.payload.languages_list.map((language) => ({
          value: language._id,
          label: language.languages_name,
        }));
        return {
          ...state,
          languageList: {
            loading: false,
            data: languageData,
          },
        };
      })
      .addCase(getLanguageList.rejected, (state, action) => {
        return {
          ...state,
          languageList: {
            loading: false,
            error: action.error.message,
            data: [],
          },
        };
      })
      .addCase(getGenersList.pending, (state) => {
        return {
          ...state,
          genersList: {
            loading: true,
            data: [],
            error: "",
          },
        };
      })
      .addCase(getGenersList.fulfilled, (state, action) => {
        const genersData = action.payload.genres_list.map((geners) => ({
          value: geners._id,
          label: geners.genres_name,
        }));
        return {
          ...state,
          genersList: {
            loading: false,
            data: genersData,
          },
        };
      })
      .addCase(getGenersList.rejected, (state, action) => {
        return {
          ...state,
          genersList: {
            loading: false,
            error: action.error.message,
            data: [],
          },
        };
      });
  },
});

export default globalListReducer.reducer;
