import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import API from "../service";
import toast from "react-hot-toast";

const initialState = {
  enebaProductList: {
    loading: false,
    is_fresh_loading: false,
    data: [],
    totalData: 0,
    error: null,
  },
  enebaKeydropList: {
    loading: false,
    is_fresh_loading: false,
    data: [],
    totalData: 0,
    error: null,
  },
  enebaSearchData: {
    loading: false,
    data: {},
    error: "",
  },
  enebaAddMutationData: {
    is_added: false,
    loading: false,
    data: {},
    error: "",
    productID: "",
    searchData: "",
    isDialogOpen: false, // New property to track dialog state
  },
  enebaEditMutationData: {
    loading: false,
    data: {},
    error: "",
  },
  serchProductInfo: {
    loading: false,
    already_added: true,
    error: "",
  },
};

export const getEnebaProductList = createAsyncThunk(
  "getEnebaProductList",
  async ({ data }) => {
    const query = new URLSearchParams(data);
    try {
      const response = await API.post(`eneba/eneba_product?${query}`);

      if (response.status) {
        if (response.message) {
          toast.success(response.message);
        }
        return response.data;
      } else {
        toast.error(response.message);
        return response; // Return the response even if status is false
      }
    } catch (error) {
      toast.error(error.message || "Something went wrong");
      throw new Error(error.message || "Something went wrong");
    }
  }
);

// product list api
export const getEnebaKeydropList = createAsyncThunk(
  "getEnebaKeydropList",
  async ({ data }) => {
    const response = await API.get("eneba/display_added_products", data);
    if (response.status) {
      return response.data;
    } else {
      throw new Error(response.data);
    }
  }
);

export const enebaProductSearch = createAsyncThunk(
  "searchEnebaProduct",
  async ({ search }) => {
    try {
      const response = await API.post(`eneba/eneba_product?search=${search}`);
      if (response.status) {
        if (response.message) {
          toast.success(response.message);
        }
        return response.data;
      } else {
        toast.error(response.message);
        return response; // Return the response even if status is false
      }
    } catch (error) {
      toast.error(error.message || "Something went wrong");
      throw new Error(error.message || "Something went wrong");
    }
  }
);

export const checkEnebaProductInDB = createAsyncThunk(
  "checkEnebaProductInDB",
  async ({ data }) => {
    try {
      const response = await API.post(
        `api/eneba/check_eneba_product_in_db?in_eneba_name=The Last of Us   `
      );
      if (response.status) {
        if (response.message) {
          toast.success(response.message);
        }
        return response.data;
      } else {
        toast.error(response.message);
        return response; // Return the response even if status is false
      }
    } catch (error) {
      toast.error(error.message || "Something went wrong");
      throw new Error(error.message || "Something went wrong");
    }
  }
);

export const enebaAddMutation = createAsyncThunk(
  "enebaAddmutation",
  async ({ data }) => {
    const query = new URLSearchParams(data);
    console.log("API being called...");
    const response = await API.post(`eneba/add_mutation?${query}`);
    console.log("API response:", response);
    if (response.status) {
      toast.success(response.message || "Product added successfully");
      return response;
    } else {
      console.log("status false in eneba");
      toast.error(response.message);
      throw new Error(response);
    }
  }
);

export const enebaEditMutation = createAsyncThunk(
  "update_mutation",
  async ({ data }) => {
    const query = new URLSearchParams(data);
    console.log("API being called...");
    const response = await API.post(`eneba/update_mutation?${query}`);
    console.log("API response:", response);
    if (response.status) {
      toast.success(response.message || "Product updated successfully");
      return response?.data;
    } else {
      throw new Error(response.data);
    }
  }
);

export const serchProductInDB = createAsyncThunk(
  "check_eneba_product_in_db",
  async ({ in_eneba_product_name }) => {
    const response = await API.get(
      `eneba/check_eneba_product_in_db?in_eneba_product_name=${in_eneba_product_name}`
    );
    console.log("API response:", response);

    return response;
    // if (response.status) {

    //   return response;
    // } else {

    // }
  }
);
const EnebaSlice = createSlice({
  name: "api",
  initialState,
  reducers: {
    openEnebaDialog: (state, action) => {
      const { productId, searchData } = action.payload;
      return {
        ...state,
        enebaAddMutationData: {
          ...state.enebaAddMutationData,
          productID: productId,
          isDialogOpen: true,
          searchData: searchData,
        },
      };
    },
    closeEnebaDialog: (state) => {
      return {
        ...state,
        enebaAddMutationData: {
          ...state.enebaAddMutationData,
          isDialogOpen: false,
          searchData: "",
        },
      };
    },
    // Add other reducers here
  },
  extraReducers: (builder) => {
    builder
      .addCase(getEnebaProductList.pending, (state, action) => {
        return {
          ...state,
          enebaProductList: {
            loading: action.meta.arg.data.is_refetch_data ? false : true,
            data: state.enebaProductList.data,
            totalData: 0,
            error: null,
          },
        };
      })
      .addCase(getEnebaProductList.fulfilled, (state, action) => {
        return {
          ...state,
          enebaProductList: {
            loading: false,
            data: action.payload?.eneba_product?.data?.S_products?.edges,
            totalData: action.payload.totalData,
          },
        };
      })
      .addCase(getEnebaProductList.rejected, (state, action) => {
        return {
          ...state,
          enebaProductList: {
            loading: false,
            data: [],
            totalData: 0,
            error: action.message,
          },
        };
      })
      .addCase(getEnebaKeydropList.pending, (state, action) => {
        return {
          ...state,
          enebaKeydropList: {
            loading: action.meta.arg.data.is_refetch_data ? false : true,
            data: state?.enebaKeydropList?.data,
            totalData: 0,
            error: null,
          },
        };
      })
      .addCase(getEnebaKeydropList.fulfilled, (state, action) => {
        return {
          ...state,
          enebaKeydropList: {
            loading: false,
            data: action.payload.added_products_on_eneba,
            totalData: action.payload.total_data,
          },
        };
      })
      .addCase(getEnebaKeydropList.rejected, (state, action) => {
        return {
          ...state,
          enebaKeydropList: {
            loading: false,
            data: [],
            totalData: 0,
            error: action.message,
          },
        };
      })
      .addCase(enebaProductSearch.pending, (state) => {
        return {
          ...state,
          enebaSearchData: {
            loading: true,
            data: {},
            error: "",
          },
        };
      })
      .addCase(enebaProductSearch.fulfilled, (state, action) => {
        console.log(
          "action.payload!!!!!!!!!!",
          action.payload?.eneba_product?.data?.S_products.edges
        );
        return {
          ...state,
          enebaSearchData: {
            loading: false,
            data: action.payload?.eneba_product?.data?.S_products.edges,
            error: "",
          },
        };
      })
      .addCase(enebaProductSearch.rejected, (state, action) => {
        return {
          ...state,
          enebaSearchData: {
            loading: false,
            data: [],
            error: action.payload,
          },
        };
      })
      .addCase(enebaAddMutation.pending, (state) => {
        return {
          ...state,
          enebaAddMutationData: {
            ...state.enebaAddMutationData,
            is_added: false,
            loading: true,
            data: [],
            error: "",
          },
        };
      })
      .addCase(enebaAddMutation.fulfilled, (state, action) => {
        return {
          ...state,
          enebaAddMutationData: {
            ...state.enebaAddMutationData,
            is_added: true,
            loading: false,
            data: action.payload,
            error: "",
          },
        };
      })
      .addCase(enebaAddMutation.rejected, (state, action) => {
        return {
          ...state,
          enebaAddMutationData: {
            ...state.enebaAddMutationData,
            is_added: false,
            loading: false,
            data: {},
            error: action.payload,
          },
        };
      })
      .addCase(enebaEditMutation.pending, (state) => {
        return {
          ...state,
          enebaEditMutationData: {
            is_edited: false,
            loading: true,
            data: [],
            error: "",
          },
        };
      })
      .addCase(enebaEditMutation.fulfilled, (state, action) => {
        return {
          ...state,
          enebaEditMutationData: {
            is_edited: true,
            loading: false,
            data: action.payload,
            error: "",
          },
        };
      })
      .addCase(enebaEditMutation.rejected, (state, action) => {
        return {
          ...state,
          enebaEditMutationData: {
            is_edited: false,
            loading: false,
            data: {},
            error: action.payload,
          },
        };
      })
      .addCase(serchProductInDB.pending, (state) => {
        return {
          ...state,
          serchProductInfo: {
            loading: true,
            already_added: true,
            error: "",
          },
        };
      })
      .addCase(serchProductInDB.fulfilled, (state, action) => {
        console.log("action----------", action.payload);
        return {
          ...state,
          serchProductInfo: {
            loading: false,
            already_added: true,
            error: "",
          },
        };
      })
      .addCase(serchProductInDB.rejected, (state, action) => {
        return {
          ...state,
          serchProductInfo: {
            loading: false,
            already_added: false,
            error: action.payload,
          },
        };
      });
  },
});
export const { openEnebaDialog, closeEnebaDialog } = EnebaSlice.actions;
export default EnebaSlice.reducer;
