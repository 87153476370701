import { createSlice, createAsyncThunk, current } from "@reduxjs/toolkit";
import API from "../service";
import toast from "react-hot-toast";

const initialState = {
  creditRequestList: {
    loading: false,
    data: [],
    totalData: 0,
    error: null,
  },
  updateCredit: {
    loading: false,
    error: "",
    is_success: false,
  },
};

export const getCreditRequestList = createAsyncThunk(
  "getCreditRequestList",
  async ({ data }) => {
    const query = new URLSearchParams(data);
    try {
      const response = await API.get(
        `/topup_request/display_topup_request`,
        data
      );

      if (response.status) {
        // if (response.message) {
        //   toast.success(response.message);
        // }
        return response.data;
      } else {
        toast.error(response.message);
        return response; // Return the response even if status is false
      }
    } catch (error) {
      toast.error(error.message || "Something went wrong");
      throw new Error(error.message || "Something went wrong");
    }
  }
);

// app credit score
export const updateUserCreditValue = createAsyncThunk(
  "updateCreditValue",
  async ({ data }) => {
    const response = await API.put(
      `/credit_router/manage_credit?_id=${data.id}`,
      { credits: data.credits }
    );

    if (response.status) {
      toast.success(response.message);
      return response.data;
    } else {
      toast.error(response.message || "something went wrong");
      throw new Error(response.message || "something went wrong");
    }
  }
);

const CreditSlice = createSlice({
  name: "api",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getCreditRequestList.pending, (state, action) => {
        return {
          ...state,
          creditRequestList: {
            loading: action.meta.arg.data.is_refetch_data ? false : true,
            data: state.creditRequestList.data,
            totalData: 0,
            error: null,
          },
        };
      })
      .addCase(getCreditRequestList.fulfilled, (state, action) => {
        return {
          ...state,
          creditRequestList: {
            loading: false,
            data: action.payload?.display_topup_requests,
            totalData: action.payload?.total_data,
          },
        };
      })
      .addCase(getCreditRequestList.rejected, (state, action) => {
        return {
          ...state,
          creditRequestList: {
            loading: false,
            data: [],
            totalData: 0,
            error: action.message,
          },
        };
      })

      .addCase(updateUserCreditValue.pending, (state) => {
        return {
          ...state,
          updateCredit: {
            loading: true,
            error: "",
            is_success: false,
          },
        };
      })
      .addCase(updateUserCreditValue.fulfilled, (state, action) => {
        const currentState = current(state);

        const updatedData = currentState?.creditRequestList?.data.map(
          (item) => {
            let updatedItem = { ...item };

            // Update topup_status only if both user_id and topup_id match
            if (
              item.user_id === action.payload.credit_management._id &&
              item._id === action.payload.credit_management.topup_id
            ) {
              console.log("Updating topup_status...");
              updatedItem.topup_status =
                action.payload.credit_management.topup_status;
            }

            // Update credits if user_id matches
            if (item.user_id === action.payload.credit_management._id) {
              updatedItem.credits = action.payload.credit_management.credits;
            }
            console.log("updatedItem", updatedItem);
            return updatedItem;
          }
        );
        console.log("updatedData:", updatedData);

        return {
          ...state,
          creditRequestList: {
            ...state.creditRequestList,
            data: updatedData,
          },
          updateCredit: {
            loading: false,
            error: "",
            is_success: true,
          },
        };
      })
      .addCase(updateUserCreditValue.rejected, (state, action) => {
        return {
          ...state,
          updateCredit: {
            loading: false,
            is_success: false,
            error: action.error.message,
          },
        };
      });
  },
});
export default CreditSlice.reducer;
