import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import serviceWorker from "../serviceWorker";
import API from "../service";
import toast from "react-hot-toast";

// init state
const initialState = {
  addreviewer: {
    is_addedd: false,
    data: [],
    loading: false,
    error: null,
  },
  reviewerList: {
    data: [],
    loading: false,
    error: null,
  },
};

// add  manually
export const addReviewer = createAsyncThunk("addReviewer", async ({ data }) => {
  const response = await API.post(`user/add_reviewer`, data);
  if (response.status) {
    toast.success(response.message || "Product updated successfully");
    return response.data;
  } else {
    toast.error(response.message || "Something went wrong");
    throw new Error(response.data);
  }
});

// get reviewer list api
export const getReviewerList = createAsyncThunk(
  "getReviewerList",
  async ({ data }) => {
    const response = await API.get("admin/reviewer_list", data);
    if (response.unauthenticated) {
      toast.error(response.message);
      localStorage.removeItem("token");
      window.location.href = "/login";
    } else {
      if (response.status) {
        return response.data;
      } else {
        throw new Error(response.data);
      }
    }
  }
);
const Reviewer = createSlice({
  name: "reviewer",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(addReviewer.pending, (state, action) => {
        return {
          ...state,
          addreviewer: {
            is_addedd: false,
            loading: true,
            data: state.addreviewer?.data,
            error: null,
          },
        };
      })
      .addCase(addReviewer.fulfilled, (state, action) => {
        return {
          ...state,
          reviewerList: {
            ...state.reviewerList,
            data: [action.payload.user, ...state.reviewerList.data],
          },
          addreviewer: {
            is_addedd: true,
            loading: false,
            data: action.payload.user,
            error: null,
          },
        };
      })
      .addCase(addReviewer.rejected, (state, action) => {
        return {
          ...state,
          addreviewer: {
            is_addedd: false,
            loading: false,
            data: state.addreviewer?.data,
            error: action.message,
          },
        };
      })
      .addCase(getReviewerList.pending, (state, action) => {
        return {
          ...state,
          reviewerList: {
            data: [],
            loading: true,
            error: null,
          },
        };
      })
      .addCase(getReviewerList.fulfilled, (state, action) => {
        return {
          ...state,
          reviewerList: {
            data: action.payload.reviewers,
            loading: false,
            error: null,
            totalData: action.payload.totalData,
          },
        };
      })
      .addCase(getReviewerList.rejected, (state, action) => {
        return {
          ...state,
          reviewerList: {
            data: [],
            loading: false,
            error: action.message,
          },
        };
      });
  },
});

export default Reviewer.reducer;
