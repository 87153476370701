import React from "react";
import { Link } from "react-router-dom";

const ErrorPage = () => {
  return (
    <div className="container mt-5 text-center">
      <div className="row justify-content-center">
        <div className="col-md-6">
          <div className="card shadow-lg p-5">
            <div className="card-body">
              <h1 className="display-3 text-danger">Error</h1>
              <h4 className="card-title mb-4">Oops! Something went wrong.</h4>

              <button
                className="btn btn-primary"
                onClick={() => {
                  window.location.href = "/home";
                }}
              >
                Go to Home
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ErrorPage;
