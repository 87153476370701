import { createAsyncThunk, createSlice, current } from "@reduxjs/toolkit";
import API from "../service";
import toast from "react-hot-toast";

const initialState = {
  userProfileList: {
    loading: false,
    data: [],
    totalData: 0,
    error: "",
  },
  updateCredit: {
    loading: false,
    error: "",
    is_success: false,
  },
  updateUserDocument: {
    loading: false,
    error: "",
    is_success: false,
  },
  AddUser: {
    loading: false,
    isAdded: false,
    data: [],
    error: "",
  },
  UpdateUser: {
    loading: false,
    isUpdated: false,
    data: [],
    error: "",
  },
  deleteUser: {
    loading: false,
    is_deleted: false,
  },
  supportTicketList: {
    loading: false,
    data: [],
    totalData: 0,
    error: "",
  },
};

// add user manually
export const addUser = createAsyncThunk("adduser", async ({ data }) => {
  const response = await API.post(`user/add_user`, data);
  if (response.status) {
    toast.success(response.message || "User added successfully");
    return response.data;
  } else {
    toast.error(response.message || "Something went wrong");
    throw new Error(response.data);
  }
});

// update product
export const updateUser = createAsyncThunk(
  "updateProduct",
  async ({ id, data }) => {
    const response = await API.put(`user/update_user?user_id=${id}`, data);
    if (response.status) {
      toast.success(response.message || "Product updated successfully");
      return response.data;
    } else {
      toast.error(response.message || "Something went wrong");
      throw new Error(response.data);
    }
  }
);

// user listing api
export const getUserProfileList = createAsyncThunk(
  "getUserProfile",
  async ({ data }) => {
    const response = await API.get("user/user_listing", data);
    if (response.unauthenticated) {
      toast.error(response.message);
      localStorage.removeItem("token");
      window.location.href = "/login";
    } else {
      if (response.status) {
        return response.data;
      } else {
        toast.error(response.message || "something went wrong");
        throw new Error(response.message || "something went wrong");
      }
    }
  }
);

// update credit score
export const updateCreditValue = createAsyncThunk(
  "updateCreditValue",
  async ({ data }) => {
    const response = await API.put(
      `credit_router/manage_credit?user_id=${data.id}`,
      { credits: data.credits }
    );

    if (response.status) {
      toast.success(response.message);
      return response.data;
    } else {
      toast.error(response.message || "something went wrong");
      throw new Error(response.message || "something went wrong");
    }
  }
);

// update user Document
export const VerifyUserDocument = createAsyncThunk(
  "updateUserDocument",
  async ({ data }) => {
    // const response = await API.put(
    //   `user/verification_doc_status_by_admin?_id=${data.id}`,
    //   { status: data.status, reason: data?.reason }
    // );
    const response = await API.put(
      `user/verification_doc_status?_id=${data.id}`,
      { status: data.status, reason: data?.reason }
    );
    if (response.status) {
      toast.success(response.message);
      return response.data;
    } else {
      toast.error(response.message || "something went wrong");
      throw new Error(response.message || "something went wrong");
    }
  }
);

// delete user api
export const deleteUserApi = createAsyncThunk(
  "deleteUser",
  async ({ id, data }) => {
    const response = await API.post(`user/delete_user?user_id=${id}`, data);
    if (response.status) {
      toast.success(response.message);
      return response.data;
    } else {
      toast.error(response.message || "Something went wrong");
      throw new Error(response.data);
    }
  }
);

export const displayTicket = createAsyncThunk(
  "displayTicket",
  async ({ data }) => {
    console.log("calledddd!!!!!!!!!!!!!");
    const response = await API.get("/contact_us/display_contact_us", data);
    if (response.status) {
      // toast.success(response.message || " deleted successfully!");
      return response.data;
    } else {
      toast.error(response.message || "Something went wrong");
      throw new Error(response.data);
    }
  }
);

const userProfile = createSlice({
  name: "userProfile",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(addUser.pending, (state, action) => {
        return {
          ...state,
          AddUser: {
            loading: true,
            isAdded: false,
            data: [],
            error: "",
          },
        };
      })
      .addCase(addUser.fulfilled, (state, action) => {
        return {
          ...state,
          userProfileList: {
            ...state.userProfileList,
            data: [action.payload.user, ...state.userProfileList.data],
          },
          AddUser: {
            loading: false,
            isAdded: true,
            data: [],
            error: "",
          },
        };
      })
      .addCase(addUser.rejected, (state, action) => {
        return {
          ...state,
          AddUser: {
            loading: false,
            isAdded: false,
            data: [],
            error: "",
          },
        };
      })
      .addCase(updateUser.pending, (state, action) => {
        return {
          ...state,
          UpdateUser: {
            loading: true,
            isUpdated: false,
            data: [],
            error: "",
          },
        };
      })
      .addCase(updateUser.fulfilled, (state, action) => {
        const data = current(state);
        const updateUserData = data?.userProfileList?.data?.map((data) => {
          if (data?._id == action?.payload?.user_profile?._id) {
            return {
              ...action?.payload?.user_profile,
            };
          }
          return data;
        });

        console.log("updateUserData", updateUserData);

        return {
          ...state,
          userProfileList: {
            ...state.userProfileList,
            data: updateUserData,
          },

          UpdateUser: {
            loading: false,
            isUpdated: true,
            data: [],
            error: "",
          },
        };
      })
      .addCase(updateUser.rejected, (state, action) => {
        return {
          ...state,
          UpdateUser: {
            loading: false,
            isUpdated: false,
            data: [],
            error: "",
          },
        };
      })
      .addCase(deleteUserApi.pending, (state) => {
        return {
          ...state,
          deleteUser: {
            loading: true,
            is_deleted: false,
          },
        };
      })
      .addCase(deleteUserApi.fulfilled, (state, action) => {
        const data = current(state);

        // const removeDeleteData = data.userProfileList.data.filter(
        //   (user_data) => user_data._id !== action.payload.delete_user._id
        // );

        const updateUserData = data?.userProfileList?.data?.map((data) => {
          if (data?._id == action?.payload?.delete_user?._id) {
            return {
              ...action?.payload?.delete_user,
            };
          }
          return data;
        });

        return {
          ...state,
          userProfileList: {
            ...state.userProfileList,
            data: updateUserData,
          },
          deleteUser: {
            loading: false,
            is_deleted: true,
          },
        };
      })
      .addCase(deleteUserApi.rejected, (state, action) => {
        return {
          ...state,
          deleteUser: {
            loading: false,
            is_deleted: false,
          },
        };
      })
      .addCase(getUserProfileList.pending, (state, action) => {
        return {
          ...state,
          deleteUser: {
            loading: true,
            data: state.userProfileList.data,
            totalData: state.userProfileList?.data?.totalData,
            error: null,
          },
        };
      })
      .addCase(getUserProfileList.fulfilled, (state, action) => {
        return {
          ...state,
          userProfileList: {
            loading: false,
            totalData: action.payload.totalData,
            data: action.payload.user_listing,
            error: null,
          },
        };
      })
      .addCase(getUserProfileList.rejected, (state, action) => {
        return {
          ...state,
          userProfileList: {
            loading: false,
            totalData: 0,
            error: action.error.message,
            data: [],
          },
        };
      })
      .addCase(updateCreditValue.pending, (state) => {
        return {
          ...state,
          updateCredit: {
            loading: true,
            error: "",
            is_success: false,
          },
        };
      })
      .addCase(updateCreditValue.fulfilled, (state, action) => {
        const data = current(state);

        const updatedData = data.userProfileList.data.map((data) => {
          console.log("data>>>>", data);
          if (data._id === action.payload.credit_management._id) {
            return {
              ...data,
              credits: action.payload.credit_management.credits,
            };
          }
          return data;
        });

        return {
          ...state,
          userProfileList: {
            ...state.userProfileList,
            data: updatedData,
          },
          updateCredit: {
            loading: false,
            error: "",
            is_success: true,
          },
        };
      })
      .addCase(updateCreditValue.rejected, (state, action) => {
        return {
          ...state,
          updateCredit: {
            loading: false,
            is_success: false,
            error: action.error.message,
          },
        };
      })
      .addCase(VerifyUserDocument.pending, (state) => {
        return {
          ...state,
          updateUserDocument: {
            loading: true,
            error: "",
            is_success: false,
          },
        };
      })
      .addCase(VerifyUserDocument.fulfilled, (state, action) => {
        const updatedData = state.userProfileList.data.map((item) => {
          if (item.b2b_user_document[0]?._id === action.payload._id) {
            const updatedB2BUserDocument = [...item.b2b_user_document];
            if (updatedB2BUserDocument.length > 0) {
              updatedB2BUserDocument[0] = {
                ...updatedB2BUserDocument[0],
                status: action.payload.status,
                reason: action.payload.reason,
              };
            }
            return { ...item, b2b_user_document: updatedB2BUserDocument };
          }
          return item;
        });

        return {
          ...state,
          userProfileList: {
            ...state.userProfileList,
            data: updatedData,
          },
          updateUserDocument: {
            loading: false,
            is_success: true,
            error: null,
          },
        };
      })
      .addCase(VerifyUserDocument.rejected, (state, action) => {
        return {
          ...state,
          updateUserDocument: {
            loading: false,
            is_success: false,
            error: action.error.message,
          },
        };
      })
      .addCase(displayTicket.pending, (state) => {
        return {
          ...state,
          supportTicketList: {
            ...state.supportTicketList,
            loading: true,
            error: null,
          },
        };
      })

      .addCase(displayTicket.fulfilled, (state, action) => {
        return {
          ...state,
          supportTicketList: {
            ...state.supportTicketList,
            loading: false,
            data: action?.payload?.contact_us_list,
            error: null,
          },
        };
      })
      .addCase(displayTicket.rejected, (state, action) => {
        return {
          ...state,
          supportTicketList: {
            loading: false,
            totalData: 0,
            error: action.error.message,
            data: [],
          },
        };
      })
      .addDefaultCase((state, action) => {});
  },
});

export default userProfile.reducer;
