import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import API from "../service";
import toast from "react-hot-toast";
import { Action } from "history";

const initialState = {
  B2COrderList: {
    loading: false,
    data: [],
    totalData: 0,
    error: "",
  },
  B2BOrderList: {
    loading: false,
    data: [],
    totalData: 0,
    error: "",
  },
};

//get order listing
export const getOrdersListB2C = createAsyncThunk(
  "getOrdersListB2C",
  async ({ data }) => {
    const response = await API.get("order/display_order", data);
    if (response.status) {
      return response.data;
    } else {
      throw new Error(response.data);
    }
  }
);
export const getOrdersListB2B = createAsyncThunk(
  "getOrdersListB2B",
  async ({ data }) => {
    const response = await API.get("order/display_b2b_order", data);

    if (response.status) {
      return response.data;
    } else {
      throw new Error(response.data);
    }
  }
);

const Order = createSlice({
  name: "order",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getOrdersListB2C.pending, (state, Action) => {
      return {
        ...state,
        B2COrderList: {
          loading: true,
          data: state.B2COrderList.data,
          totalData: 0,
          error: null,
        },
      };
    });
    builder.addCase(getOrdersListB2C.fulfilled, (state, Action) => {
      return {
        ...state,
        B2COrderList: {
          loading: false,
          data: Action?.payload?.orders,
          totalData: Action?.payload?.total_data,
          error: null,
        },
      };
    });
    builder.addCase(getOrdersListB2C.rejected, (state, Action) => {
      return {
        ...state,
        B2COrderList: {
          loading: false,
        },
      };
    });

    builder.addCase(getOrdersListB2B.pending, (state, Action) => {
      return {
        ...state,
        B2BOrderList: {
          loading: true,
          data: state.B2COrderList.data,
          totalData: 0,
          error: null,
        },
      };
    });

    builder.addCase(getOrdersListB2B.fulfilled, (state, Action) => {
      return {
        ...state,
        B2BOrderList: {
          loading: false,
          data: Action?.payload?.orders,
          totalData: Action?.payload?.total_data,
          error: null,
        },
      };
    });

    builder.addCase(getOrdersListB2B.rejected, (state, Action) => {
      return {
        ...state,
        B2BOrderList: {
          loading: false,
        },
      };
    });
  },
});
export default Order.reducer;
