import React from "react";
import axios from "axios";
import { getDataFromLocalStorage } from "./utils/getDataFromLocalStorage";
import moment from "moment";
import ErrorPage from "./Errorpage";

const userData = getDataFromLocalStorage("user");

async function sendSlackNotification(errorDetails) {
  console.log("sendSlackNotification funtion is being called");
  const formatStackTrace = (stack) => {
    const lines = stack.split("\n");
    return lines
      .map((line) => {
        const match = line.match(/(.*):(\d+):(\d+)/);
        if (match) {
          return `File: ${match[1]}, Line: ${match[2]}, Column: ${match[3]}`;
        }
        return line;
      })
      .join("\n");
  };

  const formattedStack = formatStackTrace(errorDetails.stack);

  const payload = {
    blocks: [
      {
        type: "header",
        text: {
          type: "plain_text",
          text: ":exclamation: Admin Panel Error Notification",
          emoji: true,
        },
      },
      {
        type: "section",
        fields: [
          {
            type: "mrkdwn",
            text: `*Error Message:* \n${errorDetails.message}`,
          },
          {
            type: "mrkdwn",
            text: `*Timestamp:* \n${moment().format("YYYY-MM-DD HH:mm:ss")}`,
          },
        ],
      },
      {
        type: "section",
        text: {
          type: "mrkdwn",
          text: "*User Details:*",
        },
      },
      {
        type: "section",
        fields: [
          {
            type: "mrkdwn",
            text: `*Id:* \n${userData?._id || "Unknown"}`,
          },

          {
            type: "mrkdwn",
            text: `*Email:* \n${userData?.email || "Unknown"}`,
          },
          {
            type: "mrkdwn",
            text: `*Role:* \n${userData?.role || "Unknown"}`,
          },
        ],
      },
      {
        type: "section",
        text: {
          type: "mrkdwn",
          text: `*Stack Trace:* \n\`\`\`${formattedStack.slice(0, 2000)}\`\`\``,
        },
      },
    ],
  };
  try {
    let data = {
      payload,
      platform: "Admin",
    };

    await axios.post(
      `${process.env.REACT_APP_BACKEND_BASE_URL}/notify-slack`,
      data
    );
    console.log(" Slack notification calledd..............:");
  } catch (notificationError) {
    console.error("Failed to send Slack notification:", notificationError);
  }
}

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  async componentDidCatch(error, errorInfo) {
    console.error("Error Boundary Caught an error:", error, errorInfo);
    console.log("ComponentDidCatch was called");

    await sendSlackNotification({
      component: this.props.componentName || "Unknown Component",
      message: error.message,
      stack: error.stack,
    });
  }
  render() {
    if (this.state.hasError) {
      return <ErrorPage />;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
