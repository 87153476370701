import { jwtDecode } from "jwt-decode";
export const getDataFromLocalStorage = () => {
  const token = localStorage.getItem("token");

  if (token) {
    try {
      const userData = jwtDecode(token);

      return {
        _id: userData.id || "default-id",
        email: userData.email || "default-email",
        role: userData.role || "default-company",
      };
    } catch (error) {
      console.error("Error decoding token", error);
    }
  }

  // Return dummy user data if no valid token is found
  return {
    _id: "dummy-id",
    firstName: "Dummy",
    email: "dummy@example.com",
    role: "dummy",
  };
};
