// ** Reducers Imports
import layout from "./layout";
import navbar from "./navbar";
import auth from "./auth";
import userProfile from "./userProfile";
import productReducer from "./productReducer";
import globalListReducer from "./globalListReducer";
import orderReducer from "./orderReducer";
import reviewerReducer from "./reviewerReducer";
import enebaReducer from "./enebaReducer";
import vendorReducer from "./vendorReducer";
import creditReducer from "./creditReducer";

const rootReducer = {
  navbar,
  layout,
  auth,
  userProfile,
  product: productReducer,
  order: orderReducer,
  globalList: globalListReducer,
  reviewer: reviewerReducer,
  eneba: enebaReducer,
  Vendor: vendorReducer,
  credit: creditReducer,
};

export default rootReducer;
