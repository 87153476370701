import { createAsyncThunk, createSlice, current } from "@reduxjs/toolkit";
import API from "../service";
import toast from "react-hot-toast";

// init state
const initialState = {
  productList: {
    loading: false,
    is_fresh_loading: false,
    data: [],
    totalData: 0,
    error: null,
  },
  deleteProduct: {
    loading: false,
    is_deleted: false,
  },
  updateProduct: {
    loading: false,
    is_edit_success: false,
  },
  addProduct: {
    loading: false,
    is_product_add: false,
  },
  searchGamvioProductData: {
    loading: false,
    data: [],
    is_fresh_loading: false,
  },
  addGamvioProduct: {
    loading: false,
    is_product_add: false,
  },
  visibleProduct: {
    loading: false,
  },
  notificationList: {
    loading: false,
    is_fresh_loading: false,
    data: [],
    totalData: 0,
    error: null,
  },
  singleProduct: {
    loading: false,
    is_fresh_loading: false,
    data: [],
    totalData: 0,
    error: null,
  },
};

// product list api
export const getProductList = createAsyncThunk(
  "getProductList",
  async ({ data }) => {
    const response = await API.get("product/productlist", data);
    if (response.unauthenticated) {
      toast.error(response.message);
      localStorage.removeItem("token");
      window.location.href = "/login";
    } else {
      if (response.status) {
        return response.data;
      } else {
        throw new Error(response.data);
      }
    }
  }
);

// delete product api
export const deleteProduct = createAsyncThunk(
  "deleteProduct",
  async ({ id }) => {
    const response = await API.deleteM(`product/delete_product/?_id=${id}`);
    if (response.status) {
      toast.success(response.message || "Product deleted successfully!");
      return response.data;
    } else {
      toast.error(response.message || "Something went wrong");
      throw new Error(response.data);
    }
  }
);

// update product
export const updateProduct = createAsyncThunk(
  "updateProduct",
  async ({ id, data }) => {
    const response = await API.put(`product/update_product/?_id=${id}`, data);
    if (response.status) {
      toast.success(response.message || "Product updated successfully");
      return response.data;
    } else {
      toast.error(response.message || "Something went wrong");
      throw new Error(response.data);
    }
  }
);

// add product manually
export const addProduct = createAsyncThunk("addProduct", async ({ data }) => {
  const response = await API.post(`product/add_product`, data);
  if (response.status) {
    toast.success(response.message || "Product updated successfully");
    return response.data;
  } else {
    toast.error(response.message || "Something went wrong");
    throw new Error(response.data);
  }
});

// search product from gamvio
export const searchGamvioProduct = createAsyncThunk(
  "searchGamvioProduct",
  async ({ data }) => {
    const response = await API.get(`gamivo_products/fetch_by_criteria`, data);
    if (response.status) {
      return response.data;
    } else {
      toast.error(response.message || "Something went wrong");
      throw new Error(response.data);
    }
  }
);

// add gamvio product
export const addGamvioProduct = createAsyncThunk(
  "addGamvioProduct",
  async ({ id, data }) => {
    const response = await API.post(
      `gamivo_products/insert_product?product_id=${id}`,
      data
    );
    response.status = true;

    if (response.status) {
      toast.success(response.message || "Product add successfully");
      return response.data;
    } else {
      toast.error(response.message || "Something went wrong");
      throw new Error(response.data);
    }
  }
);

//visible to user product swith api
export const visibleToUserProduct = createAsyncThunk(
  "visibleToUserProduct",
  async ({ id, data, queryString }) => {
    const response = await API.put(
      `product/change_status/?_id=${id}&value=${queryString}`,
      data
    );
    if (response.status) {
      toast.success(response.message || "Product visible to user");
      return response.data;
    } else {
      toast.error(response.message || "Something went wrong");
      throw new Error(response.data);
    }
  }
);

//get notification
export const getNotification = createAsyncThunk(
  "getNotification",
  async ({ data }) => {
    const response = await API.get("notification/display_notification", data);
    if (response.unauthenticated) {
      toast.error(response.message);
      localStorage.removeItem("token");
      window.location.href = "/login";
    } else {
      if (response.status) {
        return response.data;
      } else {
        throw new Error(response.data);
      }
    }
  }
);

// product list api
export const getSingleProduct = createAsyncThunk(
  "getSingleProduct",
  async ({ data }) => {
    const response = await API.get("product/productdetail", data);
    if (response.unauthenticated) {
      toast.error(response.message);
      localStorage.removeItem("token");
      window.location.href = "/login";
    } else {
      if (response.status) {
        return response.data;
      } else {
        throw new Error(response.data);
      }
    }
  }
);

const Product = createSlice({
  name: "product",
  initialState,
  reducers: {
    resetState: (state) => {
      return {
        ...state,
        addGamvioProduct: {
          loading: false,
          is_product_add: false,
        },
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getProductList.pending, (state, action) => {
        return {
          ...state,
          productList: {
            loading: action.meta.arg.data.is_refetch_data ? false : true,
            data: state.productList.data,
            totalData: 0,
            error: null,
          },
        };
      })
      .addCase(getProductList.fulfilled, (state, action) => {
        return {
          ...state,
          productList: {
            loading: false,
            data: action.payload.product_listing,
            totalData: action.payload.totalData,
          },
        };
      })
      .addCase(getProductList.rejected, (state, action) => {
        return {
          ...state,
          productList: {
            loading: false,
            data: [],
            totalData: 0,
            error: action.message,
          },
        };
      })
      .addCase(deleteProduct.pending, (state) => {
        return {
          ...state,
          deleteProduct: {
            loading: true,
            is_deleted: false,
          },
        };
      })
      .addCase(deleteProduct.fulfilled, (state, action) => {
        const data = current(state);

        const removeDeleteData = data.productList.data.filter(
          (game_data) => game_data._id !== action.payload.delete_product._id
        );

        return {
          ...state,
          productList: {
            ...state.productList,
            data: removeDeleteData,
          },
          deleteProduct: {
            loading: false,
            is_deleted: true,
          },
        };
      })
      .addCase(deleteProduct.rejected, (state, action) => {
        return {
          ...state,
          deleteProduct: {
            loading: false,
            is_deleted: false,
          },
        };
      })
      .addCase(updateProduct.pending, (state) => {
        return {
          ...state,
          updateProduct: {
            loading: true,
            is_edit_success: false,
          },
        };
      })
      .addCase(updateProduct.fulfilled, (state, action) => {
        const data = current(state);
        const notifications = [...data.notificationList.data];
        const updateProduct = data?.productList?.data?.map((data) => {
          if (data?._id === action.payload?.product_update._id) {
            return {
              ...action?.payload?.product_update,
            };
          }
          return data;
        });

        const findIndx = notifications.findIndex(
          (data) => data.pid === action.payload.product_update._id
        );
        if (findIndx !== -1) {
          let updateData = notifications[findIndx];
          const data = {
            ...updateData,
            lowest_price: action.payload.product_update.lowest_price,
          };
          notifications[findIndx] = data;
        }

        return {
          ...state,
          productList: {
            ...state.productList,
            data: updateProduct,
          },
          notificationList: {
            ...state.notificationList,
            data: notifications,
          },
          updateProduct: {
            loading: false,
            is_edit_success: true,
          },
        };
      })
      .addCase(updateProduct.rejected, (state, action) => {
        return {
          ...state,
          updateProduct: {
            loading: false,
            is_edit_success: false,
          },
        };
      })
      .addCase(addProduct.pending, (state) => {
        return {
          ...state,
          addProduct: {
            loading: true,
            is_product_add: false,
          },
        };
      })
      .addCase(addProduct.fulfilled, (state, action) => {
        return {
          ...state,
          productList: {
            ...state.productList,
            data: [action.payload.Add_product, ...state.productList.data],
          },
          addProduct: {
            loading: false,
            is_product_add: true,
          },
        };
      })
      .addCase(addProduct.rejected, (state, action) => {
        return {
          ...state,
          addProduct: {
            loading: false,
            is_product_add: false,
          },
        };
      })
      .addCase(searchGamvioProduct.pending, (state) => {
        return {
          ...state,
          searchGamvioProductData: {
            loading: true,
            data: state.searchGamvioProductData.data,
            is_fresh_loading:
              state.searchGamvioProductData.data.length > 0 ? false : true,
          },
        };
      })
      .addCase(searchGamvioProduct.fulfilled, (state, action) => {
        return {
          ...state,
          searchGamvioProductData: {
            loading: false,
            data: [
              ...state.searchGamvioProductData.data,
              ...action.payload.gamivo_product,
            ],
            is_fresh_loading: false,
          },
        };
      })
      .addCase(searchGamvioProduct.rejected, (state, action) => {
        return {
          ...state,
          searchGamvioProductData: {
            loading: false,
            is_fresh_loading: false,
            data: [],
          },
        };
      })
      .addCase(addGamvioProduct.pending, (state) => {
        return {
          ...state,
          addGamvioProduct: {
            loading: true,
            is_product_add: false,
          },
        };
      })
      .addCase(addGamvioProduct.fulfilled, (state, action) => {
        console.log("calledddd!!!!!!!!");
        return {
          ...state,
          productList: {
            ...state.productList,
            data: [
              action?.payload?.gamivo_product,
              ...state?.productList?.data,
            ],
          },
          addGamvioProduct: {
            loading: false,
            data: action?.payload?.gamivo_product,
            is_product_add: true,
          },
        };

        // if (action?.payload?.gamivo_product) {
        //   return {
        //     ...state,
        //     productList: {
        //       ...state.productList,
        //       data: [
        //         action?.payload?.gamivo_product,
        //         ...state?.productList?.data,
        //       ],
        //     },
        //     addGamvioProduct: {
        //       loading: false,
        //       data: action?.payload?.gamivo_product,
        //       is_product_add: true,
        //     },
        //   };
        // } else {
        //   return {
        //     ...state,
        //     productList: {
        //       ...state.productList,
        //       data: [...state?.productList?.data],
        //     },
        //     addGamvioProduct: {
        //       loading: false,
        //       data: [],
        //       is_product_add: true,
        //     },
        //   };
        // }
      })
      .addCase(addGamvioProduct.rejected, (state, action) => {
        console.log("called in rejected state ");
        return {
          ...state,
          addGamvioProduct: {
            loading: false,
            is_product_add: false,
          },
        };
      })
      .addCase(visibleToUserProduct.pending, (state, action) => {
        return {
          ...state,
          visibleProduct: {
            loading: true,
          },
        };
      })
      .addCase(visibleToUserProduct.fulfilled, (state, action) => {
        const data = current(state);
        const updatedData = data.productList.data.map((data) => {
          if (data._id === action.payload.product_detail._id) {
            return {
              ...data,
              visible_to_user: action.payload.product_detail.visible_to_user,
              top_selling: action.payload.product_detail.top_selling,
            };
          }
          return data;
        });

        return {
          ...state,
          productList: {
            ...state.productList,
            data: updatedData,
          },
          visibleProduct: {
            loading: false,
          },
        };
      })
      .addCase(visibleToUserProduct.rejected, (state, action) => {
        return {
          ...state,
          visibleProduct: {
            loading: false,
          },
        };
      })
      .addCase(getNotification.pending, (state, action) => {
        return {
          ...state,
          notificationList: {
            loading: action.meta.arg.data.is_refetch_data ? false : true,
            data: state.productList.data,
            totalData: 0,
            error: null,
          },
        };
      })
      .addCase(getNotification.fulfilled, (state, action) => {
        return {
          ...state,
          notificationList: {
            loading: false,
            data: action.payload.notification,
            totalData: action.payload.totalData,
          },
        };
      })
      .addCase(getNotification.rejected, (state, action) => {
        return {
          ...state,
          notificationList: {
            loading: false,
            data: [],
            totalData: 0,
            error: action.message,
          },
        };
      })
      .addCase(getSingleProduct.pending, (state, action) => {
        return {
          ...state,
          singleProduct: {
            loading: action.meta.arg.data.is_refetch_data ? false : true,
            data: state.productList.data,
            error: null,
          },
        };
      })
      .addCase(getSingleProduct.fulfilled, (state, action) => {
        return {
          ...state,
          singleProduct: {
            loading: false,
            data: action.payload.product_detail,
          },
        };
      })
      .addCase(getSingleProduct.rejected, (state, action) => {
        return {
          ...state,
          singleProduct: {
            loading: false,
            data: [],
            error: action.message,
          },
        };
      });
  },
});

export const { resetState } = Product.actions;
export default Product.reducer;
