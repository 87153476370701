import { createAsyncThunk, createSlice, current } from "@reduxjs/toolkit";
import API from "../service";
import toast from "react-hot-toast";

// init state
const initialState = {
  addVendor: {
    is_addedd: false,
    data: [],
    loading: false,
    error: null,
  },
  vendorList: {
    loading: false,
    data: [],
    totalData: 0,
    error: "",
  },
  updateCredit: {
    loading: false,
    error: "",
    is_success: false,
  },
  VendorOrderList: {
    loading: false,
    data: [],
    totalData: 0,
    error: null,
  },
};

// add vendor
export const addVendor = createAsyncThunk("addVendor", async ({ data }) => {
  const response = await API.post(`user/registration`, data);
  if (response.status) {
    toast.success(response.message || "Product updated successfully");
    return response.data;
  } else {
    toast.error(response.message || "Something went wrong");
    throw new Error(response.data);
  }
});

// user listing api
export const getVendorList = createAsyncThunk(
  "getVendorList",
  async ({ data }) => {
    const response = await API.get("user/user_listing", data);
    if (response.unauthenticated) {
      toast.error(response.message);
      localStorage.removeItem("token");
      window.location.href = "/login";
    } else {
      if (response.status) {
        return response.data;
      } else {
        toast.error(response.message || "something went wrong");
        throw new Error(response.message || "something went wrong");
      }
    }
  }
);

// update credit score
export const updateVendorCredit = createAsyncThunk(
  "updateVendorCredit",
  async ({ data }) => {
    const response = await API.post(
      `credit_router/manage_credit_for_venders?user_id=${data.id}`,
      { credits: data.credits }
    );

    if (response.status) {
      toast.success(response.message);
      return response.data;
    } else {
      toast.error(response.message || "something went wrong");
      throw new Error(response.message || "something went wrong");
    }
  }
);

//get vendor order
export const getOrdersListVendor = createAsyncThunk(
  "getOrdersListVendor",
  async ({ data }) => {
    console.log("data ", data);
    const response = await API.get("order/display_vender_orders", data);
    console.log("response", response);

    if (response.status) {
      return response.data;
    } else {
      throw new Error(response.data);
    }
  }
);

const Vendor = createSlice({
  name: "vendor",
  initialState,

  extraReducers: (builder) => {
    builder
      .addCase(addVendor.pending, (state, action) => {
        return {
          ...state,
          addVendor: {
            is_addedd: false,
            loading: true,
            data: state.addreviewer?.data,
            error: null,
          },
        };
      })
      .addCase(addVendor.fulfilled, (state, action) => {
        // console.log("state", );
        return {
          ...state,
          vendorList: {
            ...state.vendorList,
            data: [action?.payload?.user, ...state.vendorList.data],
          },
          addVendor: {
            is_addedd: true,
            loading: false,
            data: action.payload.user,
            error: null,
          },
        };
      })
      .addCase(addVendor.rejected, (state, action) => {
        return {
          ...state,
          addVendor: {
            is_addedd: false,
            loading: false,
            data: state.addreviewer?.data,
            error: action.message,
          },
        };
      })

      .addCase(getVendorList.pending, (state, action) => {
        return {
          ...state,
          vendorList: {
            loading: true,
            data: state.vendorList.data,
            totalData: state.vendorList?.data?.totalData,
            error: null,
          },
        };
      })
      .addCase(getVendorList.fulfilled, (state, action) => {
        return {
          ...state,
          vendorList: {
            loading: false,
            totalData: action.payload.totalData,
            data: action.payload.user_listing,
            error: null,
          },
        };
      })
      .addCase(getVendorList.rejected, (state, action) => {
        return {
          ...state,
          vendorList: {
            loading: false,
            totalData: 0,
            error: action.error.message,
            data: [],
          },
        };
      })

      .addCase(updateVendorCredit.pending, (state) => {
        return {
          ...state,
          updateCredit: {
            loading: true,
            error: "",
            is_success: false,
          },
        };
      })
      .addCase(updateVendorCredit.fulfilled, (state, action) => {
        const data = current(state);

        const updatedData = data.vendorList.data.map((data) => {
          if (data._id === action.payload.credit_management._id) {
            return {
              ...data,
              credits: action.payload.credit_management.credits,
            };
          }
          return data;
        });

        return {
          ...state,
          vendorList: {
            ...state.vendorList,
            data: updatedData,
          },
          updateCredit: {
            loading: false,
            error: "",
            is_success: true,
          },
        };
      })
      .addCase(updateVendorCredit.rejected, (state, action) => {
        return {
          ...state,
          updateCredit: {
            loading: false,
            is_success: false,
            error: action.error.message,
          },
        };
      })
      .addCase(getOrdersListVendor.pending, (state, Action) => {
        console.log("Action", Action);
        return {
          ...state,
          VendorOrderList: {
            loading: true,
            data: state.VendorOrderList.data,
            totalData: 0,
            error: null,
          },
        };
      });

    builder.addCase(getOrdersListVendor.fulfilled, (state, Action) => {
      console.log("Action", Action);
      return {
        ...state,
        VendorOrderList: {
          loading: false,
          data: Action?.payload?.orders,
          totalData: Action?.payload?.total_data,
          error: null,
        },
      };
    });

    builder.addCase(getOrdersListVendor.rejected, (state, Action) => {
      console.log("Action", Action);
      return {
        ...state,
        VendorOrderList: {
          loading: false,
        },
      };
    });
  },
});

export default Vendor.reducer;
